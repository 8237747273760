import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'r' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'r' },

    // Event Register
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'r',
                loadChildren: () =>
                    import(
                        'app/modules/event-register/brand-register/brand-register.routes'
                    ),
            },
            {
                path: 's',
                loadChildren: () =>
                    import(
                        'app/modules/event-register/supplier-register/supplier-register.routes'
                    ),
            },
            {
                path: 'q',
                loadChildren: () =>
                    import('app/modules/get-request/get-request.routes'),
            },
        ],
    },

    // 404
    {
        path: '404-not-found',
        pathMatch: 'full',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        loadChildren: () =>
            import('app/modules/admin/pages/error/error-404/error-404.routes'),
    },
    { path: '**', redirectTo: '404-not-found' },
];
